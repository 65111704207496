<!-- src/views/Home.vue -->
<template>
  <div class="session session7">
    <div class="section-font">
      <h1>Product features</h1>
      <el-row>
        <el-col :sm="24" :md="8">
          <el-image :src="section5Img1"></el-image>
          <p>No pre-sale, no insiders, max 1B supply</p></el-col
        >
        <el-col :sm="24" :md="8">
          <el-image :src="section5Img3"></el-image>
          <p>Fully audited smart contracts</p>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-image :src="section5Img2"></el-image>
          <p>Ownership renounced, immutable</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

import section5Img1 from "@/assets/icon1.png";
import section5Img2 from "@/assets/icon2.png";
import section5Img3 from "@/assets/icon3.png";

interface ImageItem {
  src: string;
  url: string;
}

const imgList = ref<ImageItem[]>([]);

async function loadImages() {
  const [iconX, iconT, iconM] = await Promise.all([
    import("@/assets/icon_X.png"),
    import("@/assets/icon_T.png"),
    import("@/assets/icon_M.png"),
  ]);

  imgList.value = [
    { src: (await iconX).default, url: "https://x.com/memefunme" },
    { src: (await iconT).default, url: "https://t.me/+RsjVaSXaL-g4MTA1" },
    { src: (await iconM).default, url: "https://memefunme.medium.com" },
  ];
}

onMounted(() => {
  loadImages();
});
</script>
<style lang="scss" scoped>
.session7 {
  min-height: 40vh;
  background-color: var(--background-color);
  color: var(--text-color);

  /* 设置标题样式 */
  .section-font {
    margin: 2rem;

    h1 {
      font-weight: 900;
      font-size: 2.5rem;
      text-align: center;
    }
    .el-image {
      display: block;
      margin: 35px auto 10px; /* 上下居中，左右居中 */
      width: 53px;
      height: 53px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center; /* 文本居中 */
      width: 210px;
      margin: 35px auto 10px;
    }
  }
}
</style>
