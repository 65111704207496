<!-- src/views/Home.vue -->
<template>
  <div class="session session8">
    <div class="section-font">
      <h1>MeMefun token use case</h1>
      <el-row>
        <el-col :sm="24" :md="8">
          <el-card
            class="use-case-card"
            :class="{ selected: isSelected }"
            @click="toggleSelected"
          >
            <p>meme fun token holders enjoy a discount on trading fees</p>
          </el-card>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-card
            class="use-case-card"
            :class="{ selected: isSelected }"
            @click="toggleSelected"
          >
            <p>memefun token holders enjoy platform revenues accordingly</p>
          </el-card>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-card
            class="use-case-card"
            :class="{ selected: isSelected }"
            @click="toggleSelected"
          >
            <p>
              Token creator gets 20% of trading fees to encourage long-term
              win-win instead of short term profits.
            </p>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

const isSelected = ref(false);
function toggleSelected() {
  isSelected.value = !isSelected.value;
}

interface ImageItem {
  src: string;
  url: string;
}

const imgList = ref<ImageItem[]>([]);

async function loadImages() {
  const [iconX, iconT, iconM] = await Promise.all([
    import("@/assets/icon_X.png"),
    import("@/assets/icon_T.png"),
    import("@/assets/icon_M.png"),
  ]);

  imgList.value = [
    { src: (await iconX).default, url: "https://x.com/memefunme" },
    { src: (await iconT).default, url: "https://t.me/+RsjVaSXaL-g4MTA1" },
    { src: (await iconM).default, url: "https://memefunme.medium.com" },
  ];
}

onMounted(() => {
  loadImages();
});
</script>
<style lang="scss" scoped>
.session8 {
  min-height: 40vh;
  background-color: var(--background-2-color);
  color: var(--text-2-color);

  /* 设置标题样式 */
  .section-font {
    padding: 2rem;

    h1 {
      font-weight: 900;
      font-size: 2.5rem;
      margin-bottom: 20px;
      text-align: center;
    }

    .selected {
      border-color: #2d2be4;
    }

    .use-case-card {
      margin: 1.25rem; /* 卡片间距 */
      border-radius: 1.81rem;
      border: 0.38rem solid var(--text-2-color);
      cursor: pointer; /* 鼠标悬停时显示手形 */
      min-height: 13rem;
      // height: 180px;
    }

    .use-case-card p {
      font-weight: 300;
      font-size: 1.13rem;
      line-height: 2rem;
    }
  }
}

@media only screen and (max-width: 991px) {
  .session8 {
    .section-font .use-case-card {
      min-height: 8rem;
    }
  }
}
</style>
