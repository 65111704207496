<!-- src/views/Home.vue -->
<template>
  <div class="session session5">
    <el-row>
      <el-col :sm="24" :md="12" class="section-font">
        <h1>Main functions</h1>
        <ul>
          <li>Create meme token in few steps</li>
          <li>
            Buy & sell and migrate to Raydium once reaches certain market cap
          </li>
          <li>
            Find-gem: provides various tools and criteria to facilitate traders
            to screen meme tokens
          </li>
          <li>live streaming</li>
        </ul>
      </el-col>
      <el-col :sm="24" :md="12" class="section-img">
        <el-image :src="sectionImg3" fit="cover"></el-image>
      </el-col>
    </el-row>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

import sectionImg3 from "@/assets/img5.png";

interface ImageItem {
  src: string;
  url: string;
}

const imgList = ref<ImageItem[]>([]);

async function loadImages() {
  const [iconX, iconT, iconM] = await Promise.all([
    import("@/assets/icon_X.png"),
    import("@/assets/icon_T.png"),
    import("@/assets/icon_M.png"),
  ]);

  imgList.value = [
    { src: (await iconX).default, url: "https://x.com/memefunme" },
    { src: (await iconT).default, url: "https://t.me/+RsjVaSXaL-g4MTA1" },
    { src: (await iconM).default, url: "https://memefunme.medium.com" },
  ];
}

onMounted(() => {
  loadImages();
});
</script>
<style lang="scss" scoped>
.session5 {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 50vh;

  .section-font {
    padding: 2rem;

    h1 {
      height: 3.13rem;
      font-size: 2.5rem;
      line-height: 3.13rem;
      text-align: center;
    }

    li {
      font-weight: 200;
      font-size: 1.5rem;
      max-width: 40rem;
    }

    li::before {
      content: "•";
      color: var(--text-color); /* 设置小圆点的颜色为白色 */
      display: inline-block;
      margin-right: 10px;
      padding-inline-start: -20px;
    }
  }

  .section-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 50vh;

    .el-image {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

// 平板
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .session5 {
    .section-font {
      padding: 2rem 1rem 0;
    }

    .section-img {
      height: 30rem;
    }
  }
}

// 手机
@media only screen and (max-width: 768px) {
  .session5 {
    .section-font {
      padding: 2rem 1rem 0;
    }

    .section-img {
      height: 17rem;
    }
  }
}
</style>
