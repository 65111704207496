<!-- src/views/Home.vue -->
<template>
  <div class="session session1">
    <el-space :direction="spaceDirection" alignment="start" :size="10">
      <transition name="el-fade-in">
        <div class="section-img">
          <el-image
            :src="sectionImg"
            :key="sectionImg"
            fit="contain"
            lazy
          ></el-image>
        </div>
      </transition>
      <div class="section-font">
        <h1>MeMefun</h1>
        <p>
          Empowering Memes, Enriching Communities, Share the Wealth with
          memefun.me
        </p>
        <div class="contact-icon">
          <ContactComponent />
        </div>
      </div>
    </el-space>

    <el-space direction="horizontal" alignment="end" :size="30">
      <div class="scrolling-text">
        <span ref="textRef">
          Since Pump.fun launched 5 months ago, it has generated over $90
          million in revenue, with over $2.3 million earned on a single day,
          July 30th. All of this revenue has gone to the team. We hope to
          redistribute these substantial earnings to the community.
        </span>
      </div>
    </el-space>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

// 判断屏幕宽度
import { useWindowSize } from "./useWindowSize";
const { width } = useWindowSize();
const spaceDirection = width.value >= 991 ? "horizontal" : "vertical";

import sectionImg from "@/assets/bg@2x.png";
import ContactComponent from "@/components/ContactComponent.vue";

const textRef = ref(null);

let textWidth = 0;
let containerWidth = 0;

const calculateTextWidth = () => {
  if (textRef.value) {
    textWidth = textRef.value.offsetWidth;
    containerWidth = textRef.value.parentNode.offsetWidth;
    console.log(
      `Text Width: ${textWidth}px, Container Width: ${containerWidth}px`
    );
  }
};

onMounted(() => {
  calculateTextWidth();
  window.addEventListener("resize", calculateTextWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", calculateTextWidth);
});
</script>
<style lang="scss" scoped>
.session1 {
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: calc(100vh - 96px);

  .section-img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .el-image {
      // width: 50vw;
      height: 40.88rem;
      margin: 1rem 5rem;
    }
  }

  .section-font {
    padding-left: 1rem;
    padding-right: 8rem;

    h1 {
      font-size: calc(5.63rem - 0.5vw);
      max-width: 26.31rem;
      font-weight: 900;
      line-height: 7.06rem;
      text-align: center;
    }
    p {
      margin-top: -4rem;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.88rem;
      max-width: 33.69rem;
      width: 80%;
      text-align: right;
    }
    .contact-icon {
      margin-top: 2.44rem;

      .link-container {
        gap: 1rem; // 链接之间的间距
      }
    }
  }

  .scrolling-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #f4c68c;
    padding: 1.56rem;
    font-weight: 500;
    font-size: 1.63rem;
    color: var(--text-color);
    line-height: 1.88rem;
    border-top: var(--text-color) 0.25rem solid;

    span {
      animation: slideLeft 20s linear infinite; // 添加动画
      margin: 0;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    @keyframes slideLeft {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-1 * 100%));
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .session1 {
    min-height: 100vh;

    .section-img {
      height: calc(90vw + 2rem);

      .el-image {
        width: 90vw;
        height: auto;
        margin: 5vw;
      }
    }

    .section-font {
      padding: 0 5vw;
      width: 90vw;

      h1 {
        max-width: 100%;
        font-size: 4rem;
        text-align: center;
        margin-top: -1rem;
      }
      p {
        max-width: 100%;
        margin-top: -4rem;
        font-weight: 400;
        font-size: 1.13rem;
        line-height: 1.88rem;
        width: 90vw;
      }
      span {
        max-width: 100%;
        display: block;
        font-weight: 200;
        font-size: 1.13rem;
        margin-top: 3rem;
        width: 90vw;
      }

      .contact-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;

        .link-container {
          gap: 2rem; // 链接之间的间距
        }
      }
    }

    .scrolling-text {
      span {
        animation: slideLeft 40s linear infinite; // 添加动画
      }
    }
  }
}
</style>
