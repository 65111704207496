<!-- src/views/Home.vue -->
<template>
  <div class="session session2">
    <el-space :direction="spaceDirection" alignment="center" :size="10">
      <div class="section-font">
        <h1>Memefun Vision</h1>
        <ul>
          <li>
            Pump.fun earns million of dollars in fee every day, but the users &
            communities do not benefit from it.
          </li>
          <li>We believe this should change.</li>
          <li>
            Memefun, where we will distribute the profits to the platform’s
            token holders.
          </li>
        </ul>
      </div>
      <div class="section-img">
        <el-image :src="sectionImg" fit="cover"></el-image>
      </div>
    </el-space>
  </div>
</template>
<script setup lang="ts">
// 判断屏幕宽度
import { useWindowSize } from "./useWindowSize";
const { width } = useWindowSize();
const spaceDirection = width.value >= 991 ? "horizontal" : "vertical";

import sectionImg from "@/assets/memeimg.png";
</script>
<style lang="scss" scoped>
.session2 {
  background-color: var(--background-3-color);
  color: var(--text-3-color);
  min-height: 33vh;

  .section-font {
    padding: 2rem;

    h1 {
      height: 3.13rem;
      font-size: 2.5rem;
      text-align: center;
      overflow: hidden;
      font-weight: 900;
    }

    li {
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 2rem;
      max-width: 40rem;
      overflow: hidden;
    }

    li::before {
      content: "•";
      color: var(--text-3-color); /* 设置小圆点的颜色为白色 */
      display: inline-block;
      margin-right: 10px;
      padding-inline-start: -20px;
    }
  }

  .section-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

// 平板
// @media only screen and (min-width: 769px) and (max-width: 991px) {
//   .session2 {
//     .section-font {
//       padding: 2rem 1rem 0;
//     }
//   }
// }

// 手机
// @media only screen and (max-width: 768px) {
//   .session2 {
//     .section-font {
//       padding: 2rem 1rem 0;
//     }

//     .section-img {
//       height: 17rem;
//     }
//   }
// }
</style>
